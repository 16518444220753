import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  HttpUrlEncodingCodec,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injectable,
  Injector,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionsSubject, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SidebarModule } from 'ng-sidebar';
import { ToastrModule } from 'ngx-toastr';

import { ApiInterceptor } from 'app/api.interceptor';
import { AppComponent } from 'app/app.component';
import { AppConfig } from 'app/app.config';
import { AdminClientComponent } from 'app/components/admin-client.component';
import { AdminCountryComponent } from 'app/components/admin-country.component';
import { AdminCurrencyComponent } from 'app/components/admin-currency.component';
import { AdminEburyComponent } from 'app/components/admin-ebury.component';
import { AdminExactOnlineComponent } from 'app/components/admin-exact-online.component';
import { AdminPaymentMethodsComponent } from 'app/components/admin-payment-methods.component';
import { AdminProviderComponent } from 'app/components/admin-provider.component';
import { AdminRegistrationComponent } from 'app/components/admin-registration.component';
import { AdminServiceComponent } from 'app/components/admin-service.component';
import { AdminShellComponent } from 'app/components/admin-shell.component';
import { AdminInvoiceCodesComponent } from 'app/components/admin-invoice-codes.component';
import { AdminTenantComponent } from 'app/components/admin-tenant.component';
import { BundleAddEditFormComponent } from "./components/bundle-add-edit-form/bundle-add-edit-form.component";
import { BundleConfiguratorComponent } from "./components/bundle-configurator/bundle-configurator.component";
import { BundlePageComponent } from './components/bundle-page.component';
import { BundleShellComponent } from "./components/bundle-shell/bundle-shell.component";
import { ConfirmActionModalComponent } from 'app/components/confirm-action-modal.component';
import { DashboardCinvioComponent } from 'app/components/dashboard-cinvio.component';
import { DashboardProviderComponent } from 'app/components/dashboard-provider.component';
import { DashboardRedirectComponent } from 'app/components/dashboard-redirect.component';
import { DashboardUserComponent } from 'app/components/dashboard-user.component';
import { DocumentsComponent } from 'app/components/documents.component';
import { DocumentsExactComponent } from 'app/components/documents-exact.component';
import { DocumentsPurchaseOrdersComponent } from 'app/components/documents-purchase-orders.component';
import { FootNoteComponent } from 'app/components/foot-note.component';
import { LoadingButtonComponent } from 'app/components/loading-button.component';
import { LoginShellComponent } from 'app/components/login-shell.component';
import { LoginComponent } from 'app/components/login.component';
import { MarketplaceComponent } from './components/marketplace.component';
import { MoneyComponent } from 'app/components/money.component';
import { MyBundlesComponent } from 'app/components/my-bundles.component';
import { MySubscriptionsComponent } from 'app/components/my-subscriptions.component';
import { NotFoundComponent } from 'app/components/not-found.component';
import { PaginationComponent } from 'app/components/pagination.component';
import { PairingComponent } from 'app/components/pairing.component';
import { ProviderCardComponent } from 'app/components/provider-card.component';
import { RechargeWalletComponent } from 'app/components/recharge-wallet.component';
import { RechargeWalletModalComponent } from 'app/components/recharge-wallet-modal.component';
import { RedirectComponent } from 'app/components/redirect.component';
import { RegisterComponent } from 'app/components/register.component';
import { ResetPasswordUpdateComponent } from 'app/components/reset-password-update.component';
import { ResetPasswordComponent } from 'app/components/reset-password.component';
import { ServiceConfiguratorComponent} from 'app/components/service-configurator.component';
import { ServicePageComponent } from 'app/components/service-page.component';
import { ServiceShellComponent } from 'app/components/service-shell.component';
import { SettingsCompanyComponent } from 'app/components/settings-company.component';
import { SettingsNotificationsComponent } from 'app/components/settings-notifications.component';
import { SettingsPayoutsComponent } from 'app/components/settings-payouts.component';
import { SettingsUsersComponent } from 'app/components/settings-users.component';
import { SettingsComponent } from 'app/components/settings.component';
import { ShellComponent } from 'app/components/shell.component';
import { SubscriptionConfiguratorComponent } from './components/subscription-configurator.component';
import { SubscriptionPageComponent } from './components/subscription-page.component';
import { SubscriptionShellComponent } from 'app/components/subscription-shell.component';
import { SubscriptionTemplateTierComponent } from './components/subscription-template-tier.component';
import { SubscriptionUpgradeModalComponent } from './components/subscription-upgrade-modal.component';
import { SubscriptionUpgradeConfirmModalComponent } from './components/subscription-upgrade-confirm-modal.component';
import { TenantPickerComponent } from 'app/components/tenant-picker.component';
import { ThankYouComponent } from 'app/components/thank-you.component';
import { TransactionCardComponent } from 'app/components/transaction-card.component';
import { TransactionListComponent } from 'app/components/transaction-list.component';
import { TransactionPageComponent } from 'app/components/transaction-page.component';
import { TransactionTableComponent } from 'app/components/transaction-table.component';
import { UpdateToastComponent } from 'app/components/update-toast.component';
import { VatNumberValidator } from 'app/directives/vat-number.directive';
import { AdminGuard } from 'app/guards/admin.guard';
import { AuthGuard } from 'app/guards/auth.guard';
import { DashboardGuard } from 'app/guards/dashboard.guard';
import { MultipleTenantGuard } from 'app/guards/multiple-tenant.guard';
import { NotLoggedInGuard } from 'app/guards/not-logged-in.guard';
import { TenantGuard } from 'app/guards/tenant.guard';
import { ApiService } from 'app/services/api.service';
import { I18nService } from 'app/services/i18n.service';
import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { ToastService } from 'app/services/toast.service';
import { ValidationService } from 'app/services/validation.service';
import { AppEffects } from 'app/store/effects';
import { REDUCER_TOKEN } from 'app/store/reducers';
import { SortOnPipe } from 'app/utils/sort-on.pipe';
import { AccountsComponent } from './components/accounts.component';

import { environment } from '../environments/environment';
import { PaymentChoiceComponent } from './components/payment-choice/payment-choice.component';
import { CustomCinvioSwitchComponent } from './components/custom-cinvio-switch/custom-cinvio-switch.component';
import {MatSidenavModule} from '@angular/material/sidenav';


export const startupServiceFactory = (startupService: AppConfig) => () =>
  startupService.load().then(() => {
    Sentry.init({ dsn: startupService.getConfig('sentryUrl') });
  });

export function HttpLoaderFactory(inj: Injector) {
  return new TranslateHttpLoader(inj.get<HttpClient>(HttpClient), './../assets/i18n/', '.json');
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error: any) {
    console.error(error);
    Sentry.captureException(error.originalError || error);
  }
}

const routes: Routes = [
  { path: '', redirectTo: '/overview', pathMatch: 'full' },
  { path: 'auth/redirect/:payload', component: RedirectComponent },
  {
    path: '',
    component: LoginShellComponent,
    data: { reuse: true },
    children: [
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'reset-password/:user/:code', component: ResetPasswordUpdateComponent },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [NotLoggedInGuard],
      },
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NotLoggedInGuard],
      },
      {
        path: 'thank-you',
        component: ThankYouComponent,
        canActivate: [NotLoggedInGuard],
      },
      {
        path: 'select-tenant',
        component: TenantPickerComponent,
        canActivate: [AuthGuard, MultipleTenantGuard],
      },
      {
        path: 'pair',
        component: PairingComponent,
        canActivate: [AuthGuard, TenantGuard],
      },
    ],
  },
  {
    path: '',
    data: { reuse: true },
    component: ShellComponent,
    children: [
      {
        path: 'overview',
        canActivate: [AuthGuard, TenantGuard],
        children: [
          { path: '', component: DashboardRedirectComponent, canActivate: [DashboardGuard] },
          { path: 'user', component: DashboardUserComponent, canActivate: [DashboardGuard] },
          { path: 'provider', component: DashboardProviderComponent, canActivate: [DashboardGuard] },
          { path: 'cinvio', component: DashboardCinvioComponent, canActivate: [DashboardGuard] },
        ],
      },
      {
        path: 'services',
        canActivate: [AuthGuard, TenantGuard],
        component: ServiceShellComponent,
        children: [
          { path: '', redirectTo: '/services/providers', pathMatch: 'full' },
          { path: 'providers', component: ServicePageComponent },
          { path: 'providers/:providerId', component: MarketplaceComponent },
          { path: 'providers/:providerId/:highlightedTierId', component: MarketplaceComponent },
          { path: 'bundles', component: MyBundlesComponent },
          { path: 'subscriptions', component: MySubscriptionsComponent },
          { path: 'configurator', component: ServiceConfiguratorComponent },
        ]
      },
      {
        path: 'subscriptions',
        canActivate: [AuthGuard, TenantGuard],
        component: SubscriptionShellComponent,
        children: [
          { path: '', redirectTo: '/subscriptions/user', pathMatch: 'full' },
          { path: 'user', component: SubscriptionPageComponent },
          { path: 'configurator', component: SubscriptionConfiguratorComponent },
        ]
      },
      {
        path: 'bundles',
        canActivate: [AuthGuard, TenantGuard],
        component: BundleShellComponent,
        children: [
          { path: '', redirectTo: '/bundles/user', pathMatch: 'full' },
          { path: 'user', component: BundlePageComponent },
          { path: 'configurator', component: BundleConfiguratorComponent },
        ]
      },
      {
        path: 'transactions',
        component: TransactionPageComponent,
        canActivate: [AuthGuard, TenantGuard],
      },
      {
        path: 'documents',
        component: DocumentsComponent,
        canActivate: [AuthGuard, TenantGuard],
        children: [
          { path: '', redirectTo: '/documents/earner', pathMatch: 'full' },
          { path: 'earner', component: DocumentsExactComponent },
          { path: 'spender', component: DocumentsExactComponent },
          { path: 'po', component: DocumentsPurchaseOrdersComponent },
        ],
      },
      {
        path: 'settings',
        canActivate: [AuthGuard, TenantGuard],
        component: SettingsComponent,
        children: [
          { path: '', redirectTo: '/settings/company', pathMatch: 'full' },
          { path: 'company', component: SettingsCompanyComponent },
          { path: 'users', component: SettingsUsersComponent },
          { path: 'notifications', component: SettingsNotificationsComponent },
          { path: 'payouts', component: SettingsPayoutsComponent },
        ],
      },
      {
        path: 'admin',
        data: { reuse: true },
        component: AdminShellComponent,
        canActivate: [AuthGuard, AdminGuard],
        children: [
          { path: '', redirectTo: '/admin/tenants', pathMatch: 'full' },
          { path: 'tenants', component: AdminTenantComponent },
          { path: 'providers', component: AdminProviderComponent },
          { path: 'payment-methods', component: AdminPaymentMethodsComponent },
          { path: 'services', component: AdminServiceComponent },
          { path: 'clients', component: AdminClientComponent },
          { path: 'countries', component: AdminCountryComponent },
          { path: 'currency', component: AdminCurrencyComponent },
          { path: 'invoice-codes', component: AdminInvoiceCodesComponent },
          { path: 'registration', component: AdminRegistrationComponent },
          { path: 'exact-online', component: AdminExactOnlineComponent },
          { path: 'ebury', component: AdminEburyComponent },
        ],
      },
      // Insert new routes HERE
      { path: '**', redirectTo: '404' },
      { path: '404', component: NotFoundComponent },
    ],
  },
];

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AccountsComponent,
        AdminClientComponent,
        AdminCountryComponent,
        AdminCurrencyComponent,
        AdminEburyComponent,
        AdminExactOnlineComponent,
        AdminPaymentMethodsComponent,
        AdminProviderComponent,
        AdminRegistrationComponent,
        AdminShellComponent,
        AdminInvoiceCodesComponent,
        AdminTenantComponent,
        AdminServiceComponent,
        AppComponent,
        BundleAddEditFormComponent,
        BundleConfiguratorComponent,
        BundlePageComponent,
        BundleShellComponent,
        ConfirmActionModalComponent,
        DashboardCinvioComponent,
        DashboardProviderComponent,
        DashboardRedirectComponent,
        DashboardUserComponent,
        DocumentsComponent,
        DocumentsExactComponent,
        DocumentsPurchaseOrdersComponent,
        FootNoteComponent,
        LoadingButtonComponent,
        LoginComponent,
        LoginShellComponent,
        MarketplaceComponent,
        MoneyComponent,
        MyBundlesComponent,
        MySubscriptionsComponent,
        NotFoundComponent,
        PaginationComponent,
        PairingComponent,
        ProviderCardComponent,
        RechargeWalletComponent,
        RechargeWalletModalComponent,
        RedirectComponent,
        RegisterComponent,
        ResetPasswordComponent,
        ResetPasswordUpdateComponent,
        ServiceConfiguratorComponent,
        ServicePageComponent,
        ServiceShellComponent,
        SettingsComponent,
        SettingsCompanyComponent,
        SettingsNotificationsComponent,
        SettingsPayoutsComponent,
        SettingsUsersComponent,
        ShellComponent,
        SubscriptionConfiguratorComponent,
        SubscriptionPageComponent,
        SubscriptionTemplateTierComponent,
        SubscriptionShellComponent,
        SubscriptionUpgradeModalComponent,
        SubscriptionUpgradeConfirmModalComponent,
        TenantPickerComponent,
        ThankYouComponent,
        TransactionCardComponent,
        TransactionListComponent,
        TransactionPageComponent,
        TransactionTableComponent,
        UpdateToastComponent,
        VatNumberValidator,
        SortOnPipe,
        PaymentChoiceComponent,
        CustomCinvioSwitchComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        EffectsModule.forRoot(AppEffects),
        FormsModule,
        HttpClientModule,
        NgbModule,
        NgxChartsModule,
        NgSelectModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),
        StoreModule.forRoot(REDUCER_TOKEN, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 25 }),
        StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
        ToastrModule.forRoot({ preventDuplicates: true }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [Injector],
            },
        }),
        MatSidenavModule
    ],
    providers: [
        ActionsSubject,
        AppConfig,
        HttpUrlEncodingCodec,
        ApiService,
        I18nService,
        LazyLoaderService,
        ToastService,
        ValidationService,
        AdminGuard,
        AuthGuard,
        MultipleTenantGuard,
        NotLoggedInGuard,
        DashboardGuard,
        TenantGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [AppConfig],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
    ]
})
export class AppModule { }
