import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidatorFn, ValidationErrors, UntypedFormControl, Validators  } from '@angular/forms';

import { ApiService } from 'app/services/api.service';
import { LazyLoaderService } from 'app/services/lazy-loader.service';
import { HydraCollection, Tenant, VatVerificationResult } from 'app/store/models';
import { CompanyIdentifierType } from 'app/store/models/country.model';
import { UenVerificationResult } from 'app/store/models/uen-verification-result.model';

@Injectable()
export class ValidationService {
  vatNumberRegex = /^((AR).+|(AT){1}U[0-9]{8}|(BE){1}[01][0-9]{9}|(BG){1}[0-9]{9,10}|(CH){1}(E)?([0-9]{6}|[0-9]{9})(TVA|MWST|IVA){1}|(CY){1}[0-9]{8}L|(CZ){1}[0-9]{8,10}|(DE){1}[0-9]{9}|(DK){1}[0-9]{8}|(EE){1}[0-9]{9}|(EL|GR){1}[0-9]{9}|(ES){1}[0-9A-Z][0-9]{7}[0-9A-Z]|(FI){1}[0-9]{8}|(FR){1}[0-9A-Z]{2}[0-9]{9}|(GB){1}([0-9]{9}([0-9]{3}){1}|[A-Z]{2}[0-9]{3}|([0-9]{9}|[0-9]{12}))|(HR){1}[0-9]{11}|(HU){1}[0-9]{8}|(IE){1}[0-9][A-Z]{1}[0-9]{5}[A-Z]{1}|(IE){1}[0-9]{7}[A-Z]{1,2}|(IT){1}[0-9]{11}|(LT){1}([0-9]{9}|[0-9]{12})|(LU){1}[0-9]{8}|(LV){1}[0-9]{11}|(MT){1}[0-9]{8}|(NL){1}[0-9]{9}B[0-9]{2}|(PL){1}[0-9]{10}|(PT){1}[0-9]{9}|(RO){1}[0-9]{2,10}|(SE){1}[0-9]{12}|(SI){1}[0-9]{8}|(SK){1}[0-9]{10})$/;
  bnByCraRegex = /^(CA){1}[0-9]{9}$/;
  companyIdentifierMap: { [k: string]: CompanyIdentifierType } = {};

  constructor(
    private api: ApiService,
    private loader: LazyLoaderService,
  ) {
    this.loader.getCountries().subscribe(
      countries => countries.forEach(c => this.companyIdentifierMap[c.value] = c.companyIdentifierType)
    );
  }

  /**
   * regex taken from https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s21.html
   */
  vatNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const vatNumber = this.composeVatNumber(control);

      if (vatNumber && !this.vatNumberRegex.test(vatNumber)) {
        return { vatNumber: { invalid: true } };
      }

      return null;
    };
  }

  vatNumberValidatorAsync(): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
      const vatNumber = this.composeVatNumber(control);

      if (vatNumber) {
        const countryCode = vatNumber.substring(0, 2);
        if (this.companyIdentifierMap[countryCode] === CompanyIdentifierType.COMPANY_IDENTIFIER_VAT && !this.vatNumberRegex.test(vatNumber)) {
          return { vatNumber: { invalid: true } };
        } else if (this.companyIdentifierMap[countryCode] === CompanyIdentifierType.COMPANY_IDENTIFIER_BN_BY_CRA && !this.bnByCraRegex.test(vatNumber)) {
          return { vatNumber: { invalid: true } };
        }

        const result = await this.api.get<{exists: boolean, pending: boolean}>({
          path: `/public/registration-request-exists/${vatNumber}`,
        }).toPromise();

        if (result.pending) {
          return { vatNumber: { pending: true } };
        }

        if (result.exists) {
          return { vatNumber: { exists: true } };
        }
      }

      return null;
    };
  }

  private composeVatNumber(control: AbstractControl): string|null {
    let vatNumber = control.value;

    if (vatNumber === null || !vatNumber) {
      return null;
    }

    if (typeof vatNumber === 'object') {
      const country = vatNumber['invoiceCountry'];
      const num = vatNumber['vat-number'];
      if (!country || !num) {
        return null;
      }
      vatNumber = country + num;
    }

    return vatNumber;
  }

  verifyVatNumberWithVIES(vatNumber: string, countryCode: string) {
    return this.api.get<VatVerificationResult>({
      path: `/verify-vat-number/${countryCode}/${vatNumber.slice(2)}`,
    });
  }

  verifyUenNumber(uenNumber: string) {
    return this.api.get<UenVerificationResult>({
      path: `/verify-uen-number/${uenNumber}`,
    });
  }

  checkIfVatNumberExists(vatNumber: string) {
    return this.api.get<HydraCollection<Tenant>>({
      path: `/tenants?vatNumber=${vatNumber}`,
    });
  }

  checkIfTenantNameExists(name: string) {
    return this.api.get<HydraCollection<Tenant>>({
      path: `/tenants?name=${name}`,
    });
  }

  emailsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let emails = control.value;
      if (emails === null || !emails) {
        return null;
      }

      let valid = true;
      emails.forEach((email: string) => {
        const emailControl = new UntypedFormControl(email, Validators.email);
        valid = valid && emailControl.valid;
      });

      return !valid ? { badEmail: { value: control.value } } : null;
    };
  }
}
