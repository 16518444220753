import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { ToastService } from 'app/services/toast.service';
import { SessionActions } from 'app/store/actions';
import { SessionState, State, Token } from 'app/store/models';
import { emptySession } from 'app/store/reducers/session.reducer';
import { selectToken } from 'app/store/selectors';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  session: SessionState = Object.assign({}, emptySession);
  tokenSubject = new BehaviorSubject<Token | 'cancel' | null>(null);
  noAuthUrls = ['komoot', 'reset', '/code', ];
  noTenantDependentUrls = ['/oauth/v2/token', '/code'];

  constructor(
    private store: Store<State>,
    private toastr: ToastService,
  ) {
    this.store.select(x => x.session).subscribe(session => {
      this.session = session;
    });
    this.store.select(selectToken).subscribe(token => {
      this.tokenSubject.next(token === null ? 'cancel' : token);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    if (request.headers && request.headers.keys().length === 0) {
      switch (request.method) {
        case 'POST':
          request = request.clone({
            setHeaders: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          });
          break;
        case 'PUT':
          request = request.clone({
            setHeaders: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          });
          break;
        case 'GET':
        case 'DELETE':
        default:
          request = request.clone({ setHeaders: {} });
          break;
      }
    }

    return next.handle(this.setAuthHeaders(
      request,
      this.session.token ? this.session.token.access_token : null,
    )).pipe(catchError((error) => {
      if (!(error instanceof HttpErrorResponse)) {
        return throwError(error);
      }
      switch (error.status) {
        case 400:
        case 422:
          if (error.error['error_description'] === 'Invalid refresh token' || error.error['error_description'] === 'Refresh token has expired') {
            this.store.dispatch(SessionActions.logout());
          }
          const title = getHumanReadableError(error);
          if (title && suppressErrorMessage(error) === false) {
            if (error && error.error && error.error.type === 'warning') {
              this.toastr.showWarning({ title });
            } else {
              this.toastr.showDanger({ title });
            }
          }
          return throwError(error);
        case 401:
          return this.handle401Error(request, next);
        case 403:
          return throwError('');
        case 500:
          // Temporary - handle in every effect?
          this.toastr.showDanger({ title: 'Server error' });
          return throwError(error);
        default:
          return throwError(error);
      }
    }));
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.session.token) {
      return throwError('');
    }

    if (!this.session.loadingToken) {
      this.tokenSubject.next(null);
      this.store.dispatch(SessionActions.refresh({ refreshToken: this.session.token.refresh_token }));
    }
    // Wait here until the tokencomes back from the refreshToken call.
    return this.tokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap(token => {
        if (token === 'cancel' || token === null) {
          return of(false);
        }
        return next.handle(this.setAuthHeaders(req, token.access_token));
      }),
    );
  }

  setAuthHeaders(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    if (!this.noAuthUrls.some(url => request.url.indexOf(url) !== -1) &&
      !request.headers.has('Authorization') &&
      token
    ) {
      request = request.clone({
        headers: request.headers.append('Authorization', 'Bearer ' + token),
      });
    }
    if (!request.headers.has('X-tenant-id') && !this.noTenantDependentUrls.some(url => request.url.indexOf(url) !== -1) && this.session.currentTenantId) {
      request = request.clone({
        headers: request.headers.append('X-tenant-id', this.session.currentTenantId),
      });
    }
    return request;
  }
}

export function getHumanReadableError(errorResponse: HttpErrorResponse): string {
  const message =
    errorResponse.error['hydra:description'] ||
    errorResponse.error['detail'] ||
    errorResponse.error.error_description ||
    errorResponse.error ||
    errorResponse.message;

  switch (message) {
    case 'Insufficient funds!':
    case 'amount: Tenant does not have enough balance.':
      return marker('You have insufficient credits.');
    case 'No enabled user contexts for this user.':
    case 'Invalid username and password combination':
    case 'Missing parameters. \"username\" and \"password\" required':
      return marker('Invalid username and password combination.');
    case 'user.email: User already exists.':
      return marker('This email is already in use.');
    case '[email]: Deze waarde is geen geldig e-mailadres.':
    case '[email]: Deze waarde mag niet leeg zijn.':
    case '[email]: Dit veld ontbreekt.':
    case '[email]: This field is missing.':
    case '[email]: This value is not a valid email address.':
    case 'email: This value is not a valid email address.':
      return marker('Please make sure you enter a valid email address.');
    case 'not a valid mobile phone number':
      return marker('The mobile phone number that was given is invalid.');
    case 'Tenant with same company name and VAT number alrady exists':
      return marker('Tenant with same company name and VAT number alrady exists.');
    case 'externalId: This value is already used.':
      return marker('Account already authorized.');
    case 'vatNumber: This value is already used.':
      return marker('Oops, looks like someone beat you to it. Your company has already requested to register.');
    case 'tenantId: Bank account number not set on tenant.':
      return marker('Bank account number not set on tenant.');
    case 'id: Cannot cancel a transaction linked to a subscription that has been renewed or upgraded.':
      return marker('Cannot cancel a transaction linked to a subscription that has been renewed or upgraded.');
    case 'legalEntity: Cannot change legal entity because of existing transactions.':
      return marker('Cannot change legal entity because of existing transactions.');
    case 'name: This value is already used.':
      return marker('A tenant with this name already exists.');
    case 'A tenant with the same company name and VAT number already exists':
      return marker('A tenant with the same company name and VAT number already exists.');
    case 'Ebury connection down.':
      return marker('Ebury connection is down, try to Re-Connect with Ebury.');
    case 'paymentReference: Amount mismatch.':
      return marker('Amounts do not match.');
    default:
      return marker('Request failed');
  }
}

export function suppressErrorMessage(errorResponse: HttpErrorResponse): boolean {
  const message =
    errorResponse.error['hydra:description'] ||
    errorResponse.error['detail'] ||
    errorResponse.error.error_description ||
    errorResponse.error ||
    errorResponse.message;

  switch (message) {
    case 'externalCompanyVatNumber: VAT number mismatch.':
    case 'billing: Tenant does not have enough balance.':
    case 'bundleTemplateServicePriceId: Tenant does not have enough balance.':
    case 'Invalid refresh token':
    case 'Refresh token has expired':
      return true;
  }

  if (message && message.slice(0, 11) === 'Stripe API:' || message.slice(0, 11) === 'Mollie API:' || message.slice(0, 14) === 'TERMStech API:') {
    return true;
  }

  return false;
}
