import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { ToastService } from 'app/services/toast.service';
import { ServiceConfigurationActions, SubscriptionConfigurationActions } from 'app/store/actions';
import { Service, State, Tenant } from 'app/store/models';
import { SubscriptionTemplate } from 'app/store/models/subscription.model';
import { selectCurrentTenant } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';

import { ConfirmActionModalComponent } from './confirm-action-modal.component';
import { SubscriptionTemplateTierComponent } from './subscription-template-tier.component';

@Component({
  selector: 'app-subscription-configurator',
  templateUrl: './subscription-configurator.component.html',
  styleUrls: ['./subscription-configurator.component.scss'],
})
export class SubscriptionConfiguratorComponent implements OnDestroy {
  loadingTemplates$: Observable<boolean>;
  loadingVersionHistory$: Observable<boolean>;
  loadingServices$: Observable<boolean>;
  services$: Observable<Service[]>;
  templateVersionHistory$: Observable<SubscriptionTemplate[]>;
  tenants$: Observable<Tenant[]>;
  serviceMap: { [k: string]: Service } = {};
  serviceIconMap: { [k: string]: string } = {};
  invalidTierMap: string[] = [];
  invalidServiceMap: string[] = [];
  templateMap: { [k: number]: SubscriptionTemplate } = {};
  currentTenant: Tenant | null = null;
  activeTemplate: SubscriptionTemplate | null = null;
  subscriptionTemplateForms: UntypedFormGroup[] = [];
  saveFlags: boolean[] = [];
  publishFlags: boolean[] = [];
  modalInstance: any;

  @ViewChild('versionHistoryModal', { static: true })
  versionHistoryModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private actions$: Actions,
    private toastr: ToastService,
  ) {
    this.loadingTemplates$ = this.store.select(x => x.subscriptionConfiguration.loadingItems);
    this.loadingVersionHistory$ = this.store.select(x => x.subscriptionConfiguration.loadingVersions);
    this.loadingServices$ = this.store.select(x => x.serviceConfiguration.loadingItems);

    this.store.select(x => x.subscriptionConfiguration.items).pipe(
      untilDestroyed(this),
      filter(notNull),
      startWith([]),
    ).subscribe(templates => {
      this.subscriptionTemplateForms = [];
      this.templateMap = [];
      templates.forEach(template => {
        const i = this.addTemplateForm(template);
        this.templateMap[i] = template;
        this.saveFlags[i] = false;
        this.publishFlags[i] = false;
      });
    });

    this.templateVersionHistory$ = this.store.select(x => x.subscriptionConfiguration.versions).pipe(
      untilDestroyed(this),
      filter(notNull),
      startWith([]),
    );

    this.services$ = this.store.select(x => x.serviceConfiguration.items).pipe(
      untilDestroyed(this),
      filter(notNull),
      startWith([]),
    );
    this.services$.subscribe(services => services.forEach(
      x => {
        this.serviceMap[x['@id']] = x;
        this.serviceIconMap[x['@id']] = x.icon;
      }
    ));

    this.tenants$ = this.store.select(x => x.serviceConfiguration.tenants).pipe(
      filter(notNull),
      startWith([]),
    );

    this.store.select(selectCurrentTenant).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(tenant => {
      this.currentTenant = tenant;
    });

    this.actions$.pipe(
      ofType(
        SubscriptionConfigurationActions.createSubscriptionTemplateSuccess,
        SubscriptionConfigurationActions.updateSubscriptionTemplateSuccess,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.saveFlags.fill(false);
      this.publishFlags.fill(false);
      this.toastr.showSuccess({
        title: marker('Your changes have been saved successfully!'),
      });
    });

    this.actions$.pipe(
      ofType(
        SubscriptionConfigurationActions.createSubscriptionTemplateFailure,
        SubscriptionConfigurationActions.updateSubscriptionTemplateFailure,
      ),
      untilDestroyed(this),
    ).subscribe(() => {
      this.saveFlags.fill(false);
      this.publishFlags.fill(false);
    });

    this.store.dispatch(ServiceConfigurationActions.loadServicesForConfigurator());
    this.store.dispatch(SubscriptionConfigurationActions.loadSubscriptionTemplates());
    this.store.dispatch(ServiceConfigurationActions.loadTenantsForConfigurator());
  }

  addTemplateForm(template?: SubscriptionTemplate, scrollAnchor?: HTMLElement): number {
    let templateForm = this.fb.group({
      id: [null],
      label: [null, Validators.required],
      allowedTenants: [null],
      published: [null],
      retroactiveRenewal: [null],
      versionId: [null],
      subscriptionTemplateTiers: this.fb.array([]),
    });
    if (template !== undefined) {
      let templateValue: any = {
        id: template.published ? undefined : template.id,
        label: template.label,
        allowedTenants: template.allowedTenants?.map(t => t['@id'] ? t['@id'] : '/api/v1/tenants/' + t.id),
        published: template.published,
        retroactiveRenewal: template.retroactiveRenewal,
        versionId: template.versionId,
        subscriptionTemplateTiers: [],
      };

      const tiers = <UntypedFormArray>templateForm.controls['subscriptionTemplateTiers'];
      template?.subscriptionTemplateTiers?.forEach(tier => {
        let tierGroup = this.createTierGroup();

        let tierValue: any = {
          label: tier.label,
          priceMonthly: tier.priceMonthly
            ? parseFloat(tier.priceMonthly)
            : null,
          priceYearly: tier.priceYearly
            ? parseFloat(tier.priceYearly)
            : null,
          highlighted: tier.highlighted,
          subscriptionTemplateTierServices: [],
        };

        const services = <UntypedFormArray>tierGroup.controls['subscriptionTemplateTierServices'];
        if (tier.subscriptionTemplateTierServices && tier.subscriptionTemplateTierServices.length > 0) {
          tier.subscriptionTemplateTierServices.forEach(() => {
            services.push(this.createServiceGroup());
          });
          tier.subscriptionTemplateTierServices.forEach((ts: any) => {
            tierValue.subscriptionTemplateTierServices.push({
              service: ts.service['@id'] ? ts.service['@id'] : '/api/v1/services/' + ts.service.id,
              tokens: ts.tokens,
              unlimited: ts.tokens === null,
            });
          });
        } else {
          services.push(this.createServiceGroup());
        }

        tiers.push(tierGroup);
        templateValue.subscriptionTemplateTiers.push(tierValue);
      });

      templateForm.reset();
      templateForm.patchValue(templateValue);
    }

    const index = this.subscriptionTemplateForms.push(templateForm) - 1;

    if (scrollAnchor !== undefined) {
      setTimeout(() => scrollAnchor.scrollIntoView({behavior: 'smooth'}), 1);
    }

    return index;
  }

  addTier(templateForm: UntypedFormGroup) {
    const modalRef = this.modalService.open(SubscriptionTemplateTierComponent, { size: 'lg' });
    let tierGroup = this.createTierGroup(true);
    modalRef.componentInstance.services$ = this.services$;
    modalRef.componentInstance.subscriptionLabel = templateForm.value.label;
    modalRef.componentInstance.currentTenant = this.currentTenant;
    modalRef.componentInstance.tierForm = tierGroup;
    modalRef.result.then((tierGroup) => {
      const tiers = <UntypedFormArray>templateForm.controls['subscriptionTemplateTiers'];
      tiers.push(tierGroup);
      templateForm.controls['subscriptionTemplateTiers'].setErrors(null);
      templateForm.markAllAsTouched();
    }, () => {});
  }

  editTier(templateForm: UntypedFormGroup, index: number) {
    const modalRef = this.modalService.open(SubscriptionTemplateTierComponent, { size: 'lg' });
    const tiers = <UntypedFormArray>templateForm.controls['subscriptionTemplateTiers'];
    let tierForm = tiers.at(index);
    modalRef.componentInstance.services$ = this.services$;
    modalRef.componentInstance.subscriptionLabel = templateForm.value.label;
    modalRef.componentInstance.currentTenant = this.currentTenant;
    modalRef.componentInstance.tierForm = tierForm;
    modalRef.componentInstance.edit = true;
    modalRef.componentInstance.subscriptionTemplate = templateForm.value.label;
  }

  removeTier(templateForm: UntypedFormGroup, index: number) {
    const tiers = <UntypedFormArray>templateForm.controls['subscriptionTemplateTiers'];
    tiers.removeAt(index);
    templateForm.markAsDirty();
  }

  getTiers(templateForm: UntypedFormGroup): UntypedFormArray {
    return <UntypedFormArray>templateForm.controls['subscriptionTemplateTiers'];
  }

  createTierGroup(addEmptyServiceGroup?: boolean): UntypedFormGroup {
    let serviceGroups: UntypedFormGroup[] = [];
    if (addEmptyServiceGroup) {
      serviceGroups.push(this.createServiceGroup());
    }

    return this.fb.group({
      label: [null, Validators.required],
      priceMonthly: [null],
      priceYearly: [null],
      highlighted: [null],
      subscriptionTemplateTierServices: this.fb.array(serviceGroups),
    });
  }

  createServiceGroup(): UntypedFormGroup {
    return this.fb.group({
      service: [null],
      tokens: [null],
      unlimited: [null],
    });
  }

  getServiceGroups(tierGroup: UntypedFormGroup): UntypedFormArray {
    return <UntypedFormArray>tierGroup.controls['subscriptionTemplateTierServices'];
  }

  discardSubscription(index: number): void
  {
    this.subscriptionTemplateForms.splice(index, 1);
  }

  viewVersionHistory(index: number): void {
    this.activeTemplate = this.templateMap[index];

    this.store.dispatch(SubscriptionConfigurationActions.loadSubscriptionTemplateVersions({ versionId: this.activeTemplate.versionId }));

    this.modalInstance = this.modalService.open(this.versionHistoryModal, { size: 'xl' });
  }

  onSubscriptionTemplateSubmit(index: number, publish: boolean) {
    const subscriptionTemplateForm = this.subscriptionTemplateForms[index];

    this.invalidServiceMap = [];
    this.invalidTierMap = [];

    if (subscriptionTemplateForm.invalid) {
      subscriptionTemplateForm.markAllAsTouched();
      return;
    }

    let payload: any = {
      label: subscriptionTemplateForm.value.label,
      published: publish,
      versionId: subscriptionTemplateForm.value.versionId || undefined,
      subscriptionTemplateTiers: [],
      allowedTenants: subscriptionTemplateForm.value.allowedTenants,
      retroactiveRenewal: subscriptionTemplateForm.value.retroactiveRenewal,
    };

    const tiers = <UntypedFormArray>subscriptionTemplateForm.controls['subscriptionTemplateTiers'];
    tiers.controls.forEach((tierGroup, tierIndex) => {
      let tierPayload: any = {
        label: tierGroup.value.label,
        priceMonthly: (tierGroup.value.priceMonthly)
          ? tierGroup.value.priceMonthly.toFixed(2)
          : null,
        priceYearly: (tierGroup.value.priceYearly)
          ? tierGroup.value.priceYearly.toFixed(2)
          : null,
        highlighted: !!tierGroup.value.highlighted,
        subscriptionTemplateTierServices: []
      };

      // Filter out empty tier services
      const tsValue = tierGroup.value['subscriptionTemplateTierServices'];
      tsValue.forEach((ts: any) => {
        if (!ts.service || (!ts.tokens && !ts.unlimited)) {
          return;
        }
        if (publish && !this.serviceMap[ts.service]?.showOnDashboard) {
          this.invalidTierMap.push(index + '-' + tierIndex);
          this.invalidServiceMap.push(ts.service);
          return;
        }
        tierPayload.subscriptionTemplateTierServices?.push({
          ...ts,
          tokens: ts.unlimited ? null : ts.tokens,
          unlimited: undefined,
        });
      });

      if (tierPayload.subscriptionTemplateTierServices?.length) {
        payload.subscriptionTemplateTiers?.push(tierPayload);
      }
    });

    if (this.invalidTierMap.length > 0) {
      return;
    }

    if (!payload.subscriptionTemplateTiers?.length) {
      subscriptionTemplateForm.controls['subscriptionTemplateTiers'].setErrors({'empty': true});
      subscriptionTemplateForm.markAllAsTouched();
      return;
    }

    if (publish) {
      const modalRef = this.modalService.open(ConfirmActionModalComponent);
      modalRef.componentInstance.title = this.translate.instant('Publish a new version');
      modalRef.componentInstance.message = this.translate.instant('Are you sure you want to publish a new version of') + ' ' + payload.label + '?'
        + ' ' + this.translate.instant('User companies paired with you will be able to start a subscription with this new version.');
      modalRef.componentInstance.confirmButtonText = marker('Yes');
      modalRef.result.then(
        () => { this.publishFlags[index] = true; this.dispatchAction(subscriptionTemplateForm, payload); },
        () => {},
      );
    } else {
      this.saveFlags[index] = true;
      this.dispatchAction(subscriptionTemplateForm, payload);
    }
  }

  dispatchAction(subscriptionTemplateForm: any, payload: any) {
    if (subscriptionTemplateForm.value.id) {
      this.store.dispatch(SubscriptionConfigurationActions.updateSubscriptionTemplate({
        payload: payload,
        id: subscriptionTemplateForm.value.id,
      }));
    } else {
      this.store.dispatch(SubscriptionConfigurationActions.createSubscriptionTemplate({
        payload: payload,
      }));
    }
  }

  deleteSubscription(index: number) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    const template = this.templateMap[index];

    modalRef.componentInstance.title = this.translate.instant('Delete Subscription');
    modalRef.componentInstance.message = this.translate.instant('Are you sure you want to delete') + ' ' + template.label + '?'
      + ' ' + this.translate.instant('User companies will not be able to start a new subscription anymore, but existing subscriptions will still be active.');
    modalRef.componentInstance.confirmButtonText = marker('Yes');
    modalRef.result.then(
      () => this.store.dispatch(SubscriptionConfigurationActions.deleteSubscriptionTemplate({
        templateId: template.id,
      })),
      () => {},
    );
  }

  ngOnDestroy() { }
}
