import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { auditTime, debounceTime, filter, startWith, withLatestFrom } from 'rxjs/operators';

import { ToastService } from 'app/services/toast.service';
import { PaymentProviderActions, UserContextActions, WalletActions } from 'app/store/actions';
import { State, UserContext } from 'app/store/models';
import { PaymentInstructions, PaymentMethod } from 'app/store/models/payment-provider.model';
import { selectCurrentTenant, selectCurrentUserContext, selectEstimationLoading } from 'app/store/selectors';
import { notNull, untilDestroyed } from 'app/utils/rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-recharge-wallet',
  templateUrl: './recharge-wallet.component.html',
  styleUrls: ['./recharge-wallet.component.scss']
})
export class RechargeWalletComponent implements OnDestroy {
  rechargeWallet$: Subscription;
  phoneNumberUpdate$: Subscription;
  currency: string = '';
  form: UntypedFormGroup;
  phoneNumberForm: UntypedFormGroup;
  recharging: boolean = false;
  amountVisible: boolean = false;
  feeMessage$: Observable<string>|undefined = undefined;
  paymentMethods$: Observable<PaymentMethod[]>;
  selectedPaymentMethod: PaymentMethod|null = null;
  termsTechId: string | null = null;
  lastPaymentProviderId: string | null = null;
  estimationLoading$: Observable<boolean> = this.store.select(selectEstimationLoading)
  estimationLoadingLocal = false;
  paymentInstructions?: PaymentInstructions;
  currentUserContext: UserContext | null = null;

  @Input()
  headerText = '';

  @Input()
  hideMessage: boolean = false;

  @Input()
  redirectUrl?: string = undefined;

  @Input()
  payLater?: boolean;
  payLaterDays?: number = 30;

  modalInstance: any;
  @ViewChild('banktransferModal', { static: true })
  banktransferModal?: TemplateRef<any>;
  @ViewChild('phoneNumberModal', { static: true })
  phoneNumberModal?: TemplateRef<any>;

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    private actions$: Actions,
    private translate: TranslateService,
    private toastr: ToastService,
    private updates$: Actions,
    private modalService: NgbModal,
  ) {
    this.form = this.fb.group({
      credits: [500, [Validators.required, Validators.min(100)]],
      paymentMethod: [null, Validators.required],
    });

    this.phoneNumberForm = this.fb.group({
      phoneNumber: [null, Validators.required],
    });

    this.paymentMethods$ = this.store.select(x => x.admin.paymentProvider.availablePaymentMethods).pipe(
      untilDestroyed(this),
      filter(notNull),
      startWith([]),
    );

    this.updates$.pipe(ofType(PaymentProviderActions.fetchEstimationSuccess), untilDestroyed(this)).subscribe((data) => {
      this.estimationLoadingLocal = false;
      this.setPaymentMethod({...this.selectedPaymentMethod, ...data});
    })
    this.updates$.pipe(ofType(PaymentProviderActions.fetchEstimationFailure), untilDestroyed(this)).subscribe(() => {
      this.estimationLoadingLocal = false;
    })

    this.form.controls.credits.valueChanges.pipe(untilDestroyed(this), debounceTime(500), auditTime(0)).subscribe((amount) => {
      if (this.form.controls.paymentMethod.value === this.termsTechId && this.payLaterDays && this.form.controls.credits.value && this.form.valid){
        this.estimationLoadingLocal = true;
        this.store.dispatch(PaymentProviderActions.fetchEstimation({paymentMethodId: this.form.controls.paymentMethod.value, termsType: `TERMS${this.payLaterDays}`, amount: amount }))
      }
    })

    this.form.controls.paymentMethod.valueChanges.pipe(untilDestroyed(this), withLatestFrom(this.paymentMethods$)).subscribe(([id, paymentMethods]) => {
      if (id === this.lastPaymentProviderId){
        return;
      }

      paymentMethods.map((paymentMethod) => {
        if (paymentMethod.code === 'terms_tech'){
          this.termsTechId = paymentMethod.id;
        }
      })
      if (id === this.termsTechId && this.payLaterDays && this.form.controls.credits.value && this.form.valid) {
        this.store.dispatch(PaymentProviderActions.fetchEstimation({paymentMethodId: id, termsType: `TERMS${this.payLaterDays}`, amount: this.form.controls.credits.value }))
        this.estimationLoadingLocal = true;
      }

      this.lastPaymentProviderId = id;
    })

    this.updates$.pipe(ofType(PaymentProviderActions.payLaterChanges), untilDestroyed(this), debounceTime(100)).subscribe(() => {
      this.setDefaultPaymentMethod();
    })

    this.setDefaultPaymentMethod();

    const tenant$ = this.store.select(selectCurrentTenant).pipe(
      filter(notNull),
      untilDestroyed(this),
    );
    tenant$.subscribe(tenant => {
      this.currency = tenant.currency.code;
    });

    this.store.select(selectCurrentUserContext).pipe(
      untilDestroyed(this),
      filter(notNull),
    ).subscribe(userContext => {
      this.currentUserContext = userContext;
    });

    this.rechargeWallet$ = this.actions$.pipe(
      ofType(WalletActions.requestRechargeWalletSuccess),
      untilDestroyed(this),
    ).subscribe(response => {
      if (response.url) {
        window.location.href = response.url;
      } else if (response.paymentInstructions) {
        this.paymentInstructions = response.paymentInstructions;
        if (this.modalInstance) {
          this.modalInstance.dismiss();
        }
        this.modalInstance = this.modalService.open(this.banktransferModal);
        this.modalInstance.result.then(
          () => { this.recharging = false },
          () => { this.recharging = false },
        );
      } else {
        this.toastr.showDanger({
          title: 'Something went wrong'
        });
      }
    });

    this.actions$.pipe(
      ofType(WalletActions.requestRechargeWalletFailure),
      untilDestroyed(this),
    ).subscribe(response => {
      this.recharging = false;
      this.toastr.showDanger({
        title: 'Something went wrong',
        message: response.error.detail,
      });
    });

    this.phoneNumberUpdate$ = this.actions$.pipe(
      ofType(UserContextActions.updateUserContextSuccess),
      untilDestroyed(this),
    ).subscribe(response => {
      if (this.currentUserContext && response.userContext) {
        this.currentUserContext.phoneNumber = response.userContext.phoneNumber;
      }
      this.onSubmit();
    });

    this.actions$.pipe(
      ofType(UserContextActions.updateUserContextFailure),
      untilDestroyed(this),
    ).subscribe(() => this.recharging = false);

    this.store.dispatch(PaymentProviderActions.loadAvailablePaymentMethods());
  }

  showAmount() {
    this.amountVisible = true;
  }

  setAmount(credits: number) {
    this.amountVisible = false;
    this.form.controls.credits.setValue(credits);
    this.calculateFee();
  }

  setPaymentMethod(paymentMethod: PaymentMethod) {
    this.form.controls.paymentMethod.setValue(paymentMethod.id);
    this.selectedPaymentMethod = paymentMethod;
    this.calculateFee();this.form.controls.credits
  }

  calculateFee() {
    if (this.selectedPaymentMethod === null || !this.form.controls.credits.value) {
      return;
    }

    let fee = 0;

    if (this.selectedPaymentMethod.fixedMargin !== null) {
      fee += parseFloat(this.selectedPaymentMethod.fixedMargin);
    }

    if (this.selectedPaymentMethod.percentageMargin !== null) {
      fee += this.form.controls.credits.value / 100 * parseFloat(this.selectedPaymentMethod.percentageMargin);
    }

    if (fee) {
      this.feeMessage$ = this.translate.get(
        'An admin fee of {{fee}} {{currency}} excl. VAT will be deducted from the chosen amount.',
        {
          fee: fee.toFixed(2),
          currency: this.currency,
        }
      );
    } else {
      this.feeMessage$ = undefined;
    }
  }

  onSubmit() {
    if (this.form.value.paymentMethod === this.termsTechId && this.currentUserContext && !this.currentUserContext.phoneNumber) {
      if (this.modalInstance) {
        this.modalInstance.dismiss();
      }
      this.modalInstance = this.modalService.open(this.phoneNumberModal);
      this.modalInstance.result.then(
        () => {
          this.recharging = true;
          this.store.dispatch(UserContextActions.updateUserContext({
            userContext: {
              id: this.currentUserContext?.id as string,
              ...this.phoneNumberForm.value,
            },
          }));
        },
        () => { },
      );
    } else {
      this.recharging = true;
      this.store.dispatch(WalletActions.requestRechargeWallet({
        amount: this.form.value.credits,
        paymentMethod: this.form.value.paymentMethod,
        description: 'Cinvio Voucher',
        redirectUrl: this.redirectUrl,
        termsType: 'TERMS' + this.payLaterDays
      }));
    }
  }

  changePayLaterDays(value: number) {
    if (this.payLaterDays === value){
      return;
    }

    if (this.termsTechId &&
      this.form.controls.paymentMethod.value === this.termsTechId &&
      this.form.controls.credits.value && this.form.valid)
    {
      this.store.dispatch(PaymentProviderActions.fetchEstimation({
        paymentMethodId: this.termsTechId,
        termsType: `TERMS${value}`,
        amount: this.form.controls.credits.value
      }))
      this.estimationLoadingLocal = true;
    }
    this.payLaterDays = value;
  }

  filterPaymentMethods(methods: PaymentMethod[] | null) {
    if (!methods){
      return [];
    }

    return methods.filter((method: PaymentMethod) => {
      if (this.payLater){
        return method.paymentProvider.code === 'BNPL';
      } else {
        return method.paymentProvider.code !== 'BNPL';
      }
    })
  }

  setDefaultPaymentMethod(){
    this.paymentMethods$.subscribe(items => items.forEach(item => {
      if (item.default){
        if (this.payLater && item.paymentProvider.code === 'BNPL'){
          this.setPaymentMethod(item);
        }

        if (!this.payLater && item.paymentProvider.code !== 'BNPL'){
          this.setPaymentMethod(item);
        }
      }
    }));
  }

  ngOnDestroy() {
    this.rechargeWallet$.unsubscribe();
    this.phoneNumberUpdate$.unsubscribe();
  }
}
