import { contramap, Ord, ordString } from 'fp-ts/es6/Ord';

import { ServiceProvider } from './service-provider.model';
import { Tenant } from './tenant.model';

export interface Service {
  '@id': string;
  '@type': string;
  id: string;
  url: string;
  icon: string;
  label: string;
  serviceProvider: ServiceProvider;
  description: string;
  defaultPrice: string | null;
  customServicePrices?: CustomServicePrice[];
  showOnDashboard: boolean;
  vatRatePercentage: string;
  vatRateLocalVat: boolean|null;
  eoInvoiceCode: string | null;
  eoSameCountryCode: string | null;
  eoDifferentEuCountryCode: string | null;
  eoDifferentNonEuCountryCode: string | null;
  eoLocalVatCountryCode: string | null;
  eoCostCenter: string | null;
  eoCostUnit: string | null;
}

export interface CustomServicePrice {
  id: string;
  tenant: Tenant;
  price: string;
}

export interface ServiceConfiguration {
  label?: string;
  description?: string | null;
  defaultPrice?: string | null;
  customServicePrices?: CustomServicePrice[];
  showOnDashboard?: boolean;
}

export const byServiceProvider: Ord<Service>
  = contramap<string, Service>(x => x.serviceProvider['@id'])(ordString);
