import { Currency } from './currency.model';
import { Entity } from './hydra.model';
import { Tenant } from './tenant.model';

export interface Account extends Entity {
  type: AccountType;
  label: string;
  currency: Currency;
  available: string;
  reserved: string;
  tenant?: Tenant;
}

export interface Voucher extends Entity {
  amount: string;
  expiration: string;
}

export enum AccountType {
  SPENDER = 'SPENDER',
  EARNER = 'EARNER',
  CINVIO = 'CINVIO',
  CINVIO_DEBT = 'CINVIO_DEBT',
  CINVIO_VAT_BALANCE = 'CINVIO_VAT_BALANCE',
}
