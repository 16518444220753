<p class="fw-bold" *ngIf="headerText">{{ headerText }}</p>
<p class="text-muted small" *ngIf="!hideMessage">{{ 'Please select or enter an amount:' | translate }}</p>
<div class="row m-0 d-flex flex-wrap">
    <button
      *ngFor="let amount of [100, 250, 500, 1000, 2000]"
      (click)="setAmount(amount)"
      [class.active]="form.value.credits === amount && !amountVisible"
      [disabled]="(estimationLoadingLocal && !(amount === form.controls.credits.value && !amountVisible)) || !((selectedPaymentMethod?.maxAmount === null) || (selectedPaymentMethod?.maxAmount >= amount))"
      class="col m-1 btn btn-outline-primary"
    >
    <money [amount]="amount" [currency]="currency"></money>
  </button>
  <button (click)="showAmount()" class="col m-1 btn btn-outline-primary" [class.active]="amountVisible">
    <b><i class="far fa-plus"></i></b>
  </button>
</div>
<p *ngIf="filterPaymentMethods(paymentMethods$ | async).length > 1" class="text-muted small mt-3">{{ 'Please select your preferred payment method:' | translate }}</p>
<p *ngIf="filterPaymentMethods(paymentMethods$ | async).length == 1" class="text-muted small mt-3">{{ 'Payment method' | translate }}:</p>
<div class="row m-0 d-flex flex-wrap">
  <div class="col px-0">
    <button *ngFor="let paymentMethod of filterPaymentMethods(paymentMethods$ | async)"
    (click)="setPaymentMethod(paymentMethod)"
    [class.active]="form.value.paymentMethod === paymentMethod.id"
    class="m-1 btn btn-outline-primary"
    ngbTooltip="{{paymentMethod.label ?? paymentMethod.code}}"
    >
      <img height="32" src="/assets/icons/payment/{{paymentMethod.code}}.svg" />
    </button>
  </div>

</div>
<form class="mt-2" [formGroup]="form">
  <div class="mb-3" [hidden]="!amountVisible">
    <label>{{ 'Amount' | translate }}</label>
    <input type="number" min="100" [max]="selectedPaymentMethod?.maxAmount ? selectedPaymentMethod?.maxAmount : 750000" class="form-control"
      [ngClass]="{'is-invalid': form.controls['credits'].invalid &&
      form.controls['credits'].touched }"
      formControlName="credits">
    <div class="invalid-feedback">
      {{ 'This is not possible.' | translate }} {{ selectedPaymentMethod?.maxAmount ? ('(maximum amount: ' + selectedPaymentMethod?.maxAmount + ')') : '' }}
    </div>
  </div>
  <div class="mt-3 mb-2" *ngIf="payLater">
    <p class="text-muted small">{{ 'Please select your payment term:' | translate }}</p>
    <div class="row w-50 m-0 d-flex flex-wrap">
      <button class="m-1 col btn btn-outline-primary" (click)="changePayLaterDays(30)" [ngClass]="payLaterDays === 30 ? 'active' : ''" [disabled]="estimationLoadingLocal && !(payLaterDays === 30)">
        <div class="d-flex justify-content-center">
          30 days
        </div>
      </button>
      <button class="m-1 col btn btn-outline-primary" (click)="changePayLaterDays(60)" [ngClass]="payLaterDays === 60 ? 'active' : ''" [disabled]="estimationLoadingLocal && !(payLaterDays === 60)">
        <div class="d-flex justify-content-center">
          60 days
        </div>
      </button>
      <button class="m-1 col btn btn-outline-primary" (click)="changePayLaterDays(90)" [ngClass]="payLaterDays === 90 ? 'active' : ''" [disabled]="estimationLoadingLocal && !(payLaterDays === 90)">
        <div class="d-flex justify-content-center">
          90 days
        </div>
      </button>
    </div>
  </div>
  <div *ngIf="feeMessage$ && (!selectedPaymentMethod?.maxAmount || selectedPaymentMethod?.maxAmount >= form.controls.credits.value)" class="row mb-2" style="width: 55%">
    <div class="col">
      <div class="info-message-container d-flex p-2 rounded-1">
        <i class="fa fa-spinner fa-spin mx-2 d-flex justify-content-center align-items-center" *ngIf="estimationLoadingLocal"></i>
        <i class="fal fa-info-circle mx-2 d-flex justify-content-center align-items-center" *ngIf="!estimationLoadingLocal"></i>
        <div class="small">{{ feeMessage$ | async }}</div>
      </div>
    </div>
  </div>
  <app-loading-button
    btnType="submit" [isDisabled]="form.invalid" [isLoading]="recharging" (ngClick)="onSubmit()"
  >
    {{ 'Top up your balance' | translate }}
  </app-loading-button>
</form>

<ng-template #banktransferModal let-self>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ paymentInstructions?.beneficiary }}&nbsp;<money [amount]="paymentInstructions?.amount" [currency]="currency"></money>
    </h4>
  </div>
  <div class="modal-body">
    <p>{{ 'Please complete the payment by transferring the full amount to the following bank account.'}}</p>
    <table class="table mb-3">
      <tr>
        <td>{{ 'Holder' | translate }}</td>
        <td>{{ paymentInstructions?.beneficiary }}</td>
      </tr>
      <tr>
        <td>{{ 'Holder address' | translate }}</td>
        <td>{{ paymentInstructions?.beneficiaryAddress }}</td>
      </tr>
      <tr *ngIf="paymentInstructions?.transitNumber">
        <td>{{ 'CAN transit number' | translate }}</td>
        <td>{{ paymentInstructions?.transitNumber }}</td>
      </tr>
      <tr *ngIf="paymentInstructions?.routingNumber">
        <td>{{ 'USA FW/ABA routing number' | translate }}</td>
        <td>{{ paymentInstructions?.routingNumber }}</td>
      </tr>
      <tr>
        <td>{{ 'Bank account number' | translate }}</td>
        <td>{{ paymentInstructions?.iban }}</td>
      </tr>
      <tr>
        <td>{{ 'BIC' | translate }}</td>
        <td>{{ paymentInstructions?.bic }}</td>
      </tr>
      <tr>
        <td>{{ 'Financial institution name' | translate }}</td>
        <td>{{ paymentInstructions?.institutionName }}</td>
      </tr>
      <tr>
        <td>{{ 'Financial institution address' | translate }}</td>
        <td>{{ paymentInstructions?.institutionAddress }}</td>
      </tr>
      <tr>
        <td>{{ 'Payment reference' | translate }}</td>
        <td>{{ paymentInstructions?.paymentReference }}</td>
      </tr>
    </table>
    <form>
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Instructions have also been mailed to:' | translate }}</label>
        <input class="form-control" value="{{ paymentInstructions?.sentTo }}" disabled />
      </div>
      <button type="button" class="btn btn-block btn-primary" (click)="self.dismiss()">
        {{ 'Close' | translate}}
      </button>
    </form>
  </div>
</ng-template>

<ng-template #phoneNumberModal let-self>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'Provide phone number' | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <p>{{ "Your phone number enables smooth communication and keeps your 'Pay later' service onboarding easy and hassle-free." | translate }}</p>
    <form class="mt-3" [formGroup]="phoneNumberForm">
      <div class="mb-3">
        <label class="form-label center-block">{{ 'Phone number' | translate }}</label>
        <input class="form-control" formControlName="phoneNumber">
      </div>
      <button type="button" class="btn btn-block btn-primary" (click)="self.close()" [disabled]="phoneNumberForm.controls['phoneNumber'].invalid">
        {{ 'Save and Top up' | translate}}
      </button>
    </form>
  </div>
</ng-template>

