<h2>{{ 'Legal entities' | translate }}</h2>
<button class="btn btn-link" (click)="newLegalEntity()">
  {{ 'New legal entity' | translate }}
</button>
<table class="table">
  <tr>
    <th>{{ 'Name' | translate }}</th>
    <th>{{ 'Timezone' | translate }}</th>
    <th>{{ 'Bank account' | translate }}</th>
    <th>{{ 'Voucher bank account' | translate }}</th>
    <th></th>
  </tr>
  <tr *ngFor="let legalEntity of legalEntities$ | async | sortOn: 'name'">
    <td>{{ legalEntity.name }}</td>
    <td>{{ legalEntity.timezone }}</td>
    <td>{{ legalEntity.bankAccountNumber }}/{{ legalEntity.bic }}</td>
    <td>{{ legalEntity.voucherBankAccountNumber }}/{{ legalEntity.voucherBic }}</td>
    <td>
      <button class="btn btn-sm btn-link" (click)="editLegalEntity(legalEntity)">
        {{ 'Edit legal entity' | translate }}
      </button>
      <!--
      <button class="btn btn-sm btn-link" (click)="deleteLegalEntity(legalEntity)">
        {{ 'Delete legal entity' | translate }}
      </button>
      -->
    </td>
  </tr>
</table>

<h2>{{ 'Service providers' | translate }}</h2>
<button class="btn btn-link" (click)="newServiceProvider()">
  {{ 'New service provider' | translate }}
</button>
<table class="table">
  <tr>
    <th>{{ 'Name' | translate }}</th>
    <th>{{ 'Bank account' | translate }}</th>
    <th>{{ 'Margin' | translate }}</th>
    <th></th>
  </tr>
  <tr *ngFor="let serviceProvider of serviceProviders$ | async | sortOn: 'name'">
    <td>
      <img *ngIf="serviceProvider.logo" [src]="sanitize(serviceProvider.logo)" class="logo-small"/>
      {{ serviceProvider.name }}
    </td>
    <td>{{ serviceProvider.bankAccountNumber }}/{{ serviceProvider.bic }}</td>
    <td>{{ serviceProvider.percentageMargin }}% + {{ serviceProvider.fixedMargin }}{{ serviceProvider.tenant.currency.code }}</td>
    <td>
      <button class="btn btn-sm btn-link" (click)="editServiceProvider(serviceProvider)">
        {{ 'Edit service provider' | translate }}
      </button>
      <!--
      <button class="btn btn-sm btn-link" (click)="deleteServiceProvider(serviceProvider)">
        {{ 'Delete service provider' | translate }}
      </button>
      -->
    </td>
  </tr>
</table>

<ng-template #legalEntityModal let-self>
  <div class="modal-header">
    <h4 *ngIf="!activeLegalEntity" class="modal-title">{{ 'Add new legal entity' | translate }}</h4>
    <h4 *ngIf="activeLegalEntity" class="modal-title">{{ 'Edit legal entity' | translate }} {{ activeLegalEntity.name }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="activeLegalEntity ? onEditLegalEntitySubmit() : onCreateLegalEntitySubmit()"
      [formGroup]="legalEntityForm"
      class="p-3"
    >
      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label class="form-label center-block">ID</label>
            <input class="form-control" formControlName="id" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Tenant' | translate }}</label>
            <ng-select
              formControlName="tenant"
              [items]="(activeLegalEntity ? tenantOptionsAll$ : tenantOptions$) | async"
              bindLabel="label"
              bindValue="value"
            ></ng-select>
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Name' | translate }}</label>
            <input class="form-control" formControlName="name" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Timezone' | translate }}</label>
            <input class="form-control" formControlName="timezone" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Bank account number' | translate }}</label>
            <input class="form-control" formControlName="bankAccountNumber" pattern="[^\s]*" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Bank account BIC' | translate }}</label>
            <input class="form-control" formControlName="bic" pattern="[^\s]*" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Voucher bank account number' | translate }}</label>
            <input class="form-control" formControlName="voucherBankAccountNumber" pattern="[^\s]*" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Voucher bank account BIC' | translate }}</label>
            <input class="form-control" formControlName="voucherBic" pattern="[^\s]*" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Admin fee VAT/GST Rate Percentage' | translate }}</label>
            <input
              class="form-control"
              formControlName="cashInFeeVatRatePercentage"
              type="number"
            />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Transaction fee VAT/GST Rate Percentage' | translate }}</label>
            <input
              class="form-control"
              formControlName="transactionFeeVatRatePercentage"
              type="number"
            />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Voucher invoice template' | translate }}</label>
            <input class="form-control" formControlName="voucherInvoiceTemplate">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Voucher invoice email template' | translate }}</label>
            <input class="form-control" formControlName="voucherInvoiceEmailTemplate">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Transactions fee invoice template' | translate }}</label>
            <input class="form-control" formControlName="transactionsFeeInvoiceTemplate">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Transactions fee invoice email template' | translate }}</label>
            <input class="form-control" formControlName="transactionsFeeInvoiceEmailTemplate">
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Division' | translate }}</label>
            <input class="form-control" formControlName="eoDivision">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Sender e-mail' | translate }}</label>
            <input class="form-control" formControlName="eoSenderEmail">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Admin fee Invoice Code' | translate }}</label>
            <input class="form-control" formControlName="eoCashInFeeInvoiceCode" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Admin fee Same Country Code' | translate }}</label>
            <input class="form-control" formControlName="eoCashInFeeSameCountryCode" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Admin fee Different EU Country Code' | translate }}</label>
            <input class="form-control" formControlName="eoCashInFeeDifferentEuCountryCode" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Admin fee Different no EU Country Code' | translate }}</label>
            <input class="form-control" formControlName="eoCashInFeeDifferentNonEuCountryCode" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Transaction fee Invoice Code' | translate }}</label>
            <input class="form-control" formControlName="eoTransactionFeeInvoiceCode" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Transaction fee Same Country Code' | translate }}</label>
            <input class="form-control" formControlName="eoTransactionFeeSameCountryCode" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Transaction fee Different EU Country Code' | translate }}</label>
            <input class="form-control" formControlName="eoTransactionFeeDifferentEuCountryCode" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Transaction fee Different no EU Country Code' | translate }}</label>
            <input class="form-control" formControlName="eoTransactionFeeDifferentNonEuCountryCode" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Admin fee invoice template' | translate }}</label>
            <input class="form-control" formControlName="adminFeeInvoiceTemplate">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Admin fee invoice email template' | translate }}</label>
            <input class="form-control" formControlName="adminFeeInvoiceEmailTemplate">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Admin fee journal' | translate }}</label>
            <input class="form-control" formControlName="eoAdminFeeJournal">
          </div>
        </div>
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingLegalEntity$ | async">
        {{ 'Submit' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>

<ng-template #serviceProviderModal let-self>
  <div class="modal-header">
    <h4 *ngIf="!activeServiceProvider" class="modal-title">{{ 'Add new service provider' | translate }}</h4>
    <h4 *ngIf="activeServiceProvider" class="modal-title">{{ 'Edit service provider' | translate }} {{ activeServiceProvider.name }}</h4>
    <button type="button" class="btn-close" tabindex="-1" (click)="self.dismiss()">
      <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="activeServiceProvider ? onEditServiceProviderSubmit() : onCreateServiceProviderSubmit()"
      [formGroup]="serviceProviderForm"
      class="p-3"
    >
      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label class="form-label center-block">ID</label>
            <input class="form-control" formControlName="id" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Tenant' | translate }}</label>
            <ng-select
              formControlName="tenant"
              [items]="(activeServiceProvider ? tenantOptionsAll$ : tenantOptions$) | async"
              bindLabel="label"
              bindValue="value"
            ></ng-select>
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Name' | translate }}</label>
            <input class="form-control" formControlName="name" />
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Platform URL' | translate }}</label>
            <input class="form-control" formControlName="platformUrl">
          </div>
          <div class="mb-3 mb-4">
            <label class="form-label center-block">{{ 'Logo' | translate }} </label>
            <div class="image-upload d-flex justify-content-start">
              <img *ngIf="activeServiceProvider?.logo" [src]="sanitize(activeServiceProvider?.logo)" class="logo" />
              <input name="logo" type="file" class="form-control-file" accept="image/*" (change)="handleLogoChange($event)">
            </div>
          </div>
          <div class="form-check mb-1">
            <input
              id="resellerSetupOff"
              class="form-check-input"
              type="radio"
              formControlName="resellerSetup"
              value="off"
            />
            <label class="form-label form-check-label" for="resellerSetupOff">
              {{ 'cinvio payment platform setup' | translate }}
              <i class="far fa-info-circle" ngbTooltip="{{ 'This will also create a monthly separate service fee invoice between the legal entity and the service provider' | translate }}"></i>
            </label>
          </div>
          <div class="form-check mb-3">
            <input
              id="resellerSetupOn"
              class="form-check-input"
              type="radio"
              formControlName="resellerSetup"
              value="on"
            />
            <label class="form-label form-check-label" for="resellerSetupOn">
              {{ 'cinvio service reseller setup' | translate }}
            </label>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Fixed Margin' | translate }}</label>
            <input class="form-control" formControlName="fixedMargin" type="number">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Percentage Margin' | translate }}</label>
            <input class="form-control" formControlName="percentageMargin" type="number">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Subscription VAT/GST Rate Percentage' | translate }}</label>
            <input class="form-control" formControlName="subscriptionVatRatePercentage" type="number">
          </div>
          <div class="form-check mb-3">
            <input class="form-check-input mt-2" type="checkbox" formControlName="subscriptionVatRateLocalVat" id="subscriptionVatRateLocalVat" />
            <label class="form-label form-check-label" for="subscriptionVatRateLocalVat">
              {{ 'Subscription Local VAT' | translate }}
              <i class="far fa-info-circle" ngbTooltip="{{ 'Enable this parameter if cinvio should charge VAT/GST for every subscription start transaction between cinvio (as reseller) and the user company on this service provider.' | translate }}"></i>
            </label>
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Bank account number' | translate }}</label>
            <input class="form-control" formControlName="bankAccountNumber" pattern="[^\s]*">
          </div>
          <div class="mb-3">
            <label class="form-label center-block">{{ 'BIC' | translate }}</label>
            <input class="form-control" formControlName="bic" pattern="[^\s]*">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label center-block">{{ 'Invoicing Software' | translate }}</label>
            <ng-select
              formControlName="invoicingSoftware"
              [items]="invoicingSoftwareOptions$ | async"
              [clearable]="true"
              bindLabel="label"
              bindValue="value"
            ></ng-select>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3" *ngIf="serviceProviderForm.value['invoicingSoftware'] === 'EXACT_ONLINE'">
            <label class="form-label center-block">{{ 'Division' | translate }}</label>
            <input class="form-control" formControlName="eoDivision">
          </div>
          <div class="mb-3" *ngIf="serviceProviderForm.value['invoicingSoftware'] === 'EXACT_ONLINE'">
            <label class="form-label center-block">{{ 'Transactions invoice template' | translate }}</label>
            <input class="form-control" formControlName="transactionsInvoiceTemplate">
          </div>
          <div class="mb-3" *ngIf="serviceProviderForm.value['invoicingSoftware'] === 'EXACT_ONLINE'">
            <label class="form-label center-block">{{ 'Journal' | translate }}</label>
            <input class="form-control" formControlName="eoJournal">
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3" *ngIf="serviceProviderForm.value['invoicingSoftware'] === 'EXACT_ONLINE'">
            <label class="form-label center-block">{{ 'Sender e-mail' | translate }}</label>
            <input class="form-control" formControlName="eoSenderEmail">
          </div>
          <div class="mb-3" *ngIf="serviceProviderForm.value['invoicingSoftware'] === 'EXACT_ONLINE'">
            <label class="form-label center-block">{{ 'Transactions invoice email template' | translate }}</label>
            <input class="form-control" formControlName="transactionsInvoiceEmailTemplate">
          </div>
        </div>
      </div>
      <app-loading-button btnType="submit" [isLoading]="savingServiceProvider$ | async">
        {{ 'Submit' | translate }}
      </app-loading-button>
    </form>
  </div>
</ng-template>
