<div class="modal-header">
  <h4 class="modal-title">{{ 'Transaction details' | translate }}</h4>
  <button type="button" class="btn-close" tabindex="-1" (click)="modal.dismiss()">
    <span _ngcontent-avm-c102="" aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <table class="table table-striped">

    <tr>
      <td class="text-muted">{{ 'Date' | translate }}</td>
      <td class="text-end">
        {{ transaction.chargedAt | date:'dd-MM-yyyy HH:mm:ss' }}
      </td>
    </tr>

    <tr *ngIf="getMainPosting(transaction); let mainPosting">
      <td class="text-muted">{{ 'Amount' | translate }}</td>
      <td class="text-end">
        <b *ngIf="transaction.softCharged">
          {{ 'Reserved' | translate }}:
          (<money [amount]="mainPosting.amount" [currency]="mainPosting.currency"></money>)
        </b>
        <b
          *ngIf="!transaction.softCharged"
          [ngClass]="parseInt(mainPosting.amount, 10) >= 0 ? 'text-success' : 'text-danger'"
        >
          <money [amount]="mainPosting.amount" [currency]="mainPosting.currency"></money>
        </b>
      </td>
    </tr>

    <tr *ngIf="
      showExchangeRate(transaction) && {
        earner: getEarnerPosting(transaction),
        spender: getSpenderPosting(transaction),
      }; let context
    ">
      <td class="text-muted">{{ 'Exchange Rate' | translate }}</td>
      <td class="text-end">{{ context.spender?.exchangeRate?.fromBase }} ({{ context.earner?.currency?.code }} -> {{ context.spender?.currency?.code }})</td>
    </tr>

    <tr *ngIf="getServiceLabel(transaction)">
      <td class="text-muted">{{ 'Service' | translate }}</td>
      <td class="text-end">{{ getServiceLabel(transaction) }}</td>
    </tr>

    <tr>
      <td class="text-muted">{{ 'Description' | translate }}</td>
      <td class="text-end">{{ transaction.description | translate }}</td>
    </tr>

    <tr *ngIf="accountType !== 'SPENDER' && getUserCompanyName(transaction) !== null">
      <td class="text-muted">{{ 'Company' | translate }}</td>
      <td class="text-end">{{ getUserCompanyName(transaction) }}</td>
    </tr>

    <tr *ngIf="accountType !== 'EARNER'">
      <td class="text-muted">{{ 'Service Provider' | translate }}</td>
      <td class="text-end">
        <span *ngIf="getServiceProviderName(transaction)">
          {{ getServiceProviderName(transaction) }}
        </span>
      </td>
    </tr>

    <tr *ngIf="transaction.reference">
      <td class="text-muted">{{ 'Reference' | translate }}</td>
      <td class="text-end">{{ transaction.reference }}</td>
    </tr>

    <tr *ngFor="let row of transaction.data | keyvalue">
      <td class="text-muted">{{ row.key }}</td>
      <td *ngIf="isScalar(row.value)" class="text-end">{{ row.value }}</td>
      <td *ngIf="!isScalar(row.value)"><pre>{{ row.value | json }}</pre></td>
    </tr>

  </table>
  <div class="d-flex justify-content-end">
    <app-loading-button
      btnType="button"
      btnClass="btn btn-danger"
      *ngIf="allowRefund(transaction)"
      (click)="refund(transaction)"
      [isLoading]="cancelling"
    >
      {{ 'Refund' | translate }}
    </app-loading-button>
  </div>
</div>
