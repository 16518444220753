import { makeActions, ofType } from 'app/store/actions/utils';
import { EburyPayment, EburyNotification } from 'app/store/models';

export const EburyActions = makeActions({
  loadEburyPayments: ofType<{
    query?: string;
  }>(),
  loadEburyPaymentsSuccess: ofType<{ items: EburyPayment[]; }>(),
  loadEburyPaymentsFailure: ofType<{ error: any; }>(),

  loadEburyNotifications: null,
  loadEburyNotificationsSuccess: ofType<{ items: EburyNotification[]; }>(),
  loadEburyNotificationsFailure: ofType<{ error: any; }>(),

  processEburyNotification: ofType<{
    notificationId: string;
    paymentReference: string;
  }>(),
  processEburyNotificationSuccess: null,
  processEburyNotificationFailure: ofType<{ error: any; }>(),
});
