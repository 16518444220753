<div style="margin:-1rem" class="pt-3">
  <ul class="nav nav-tabs ps-3">
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/tenants">
        {{ 'Tenants' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/providers">
      {{ 'Providers' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/services">
        {{ 'Services' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/clients">
        {{ 'OAuth Clients' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/countries">
      {{ 'Countries' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/currency">
        {{ 'Currencies' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/payment-methods">
      {{ 'Payment methods' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/registration">
        {{ 'Registration' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/invoice-codes">
        {{ 'Invoicing' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/exact-online">
        {{ 'Exact Online' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/ebury">
        {{ 'Ebury' | translate }}
      </a>
    </li>
  </ul>
  <div class="p-3">
    <router-outlet></router-outlet>
  </div>
</div>
