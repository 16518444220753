<div class="row">
  <h2>{{ 'Process Ebury payments' | translate }}</h2>
  <p>Below you can see payments initiated from our system on the left and incomming payment notifications from Ebury, which could not be processed, on the right. You can select a pair and process it manually if the amount matches.</p>
  <form class="row row-cols-lg-auto align-items-center mb-4"
    [formGroup]="form"
    (ngSubmit)="onFormSubmit()"
  >
    <div class="col-sm-3">
      <label class="form-label me-3">{{ 'Payment' | translate }}</label>
      <ng-select
        formControlName="paymentReference"
        [items]="(initiatedPayments$ | async) || {}"
        bindLabel="paymentReference"
        bindValue="paymentReference"
      ></ng-select>
    </div>
    <div class="col-sm-3">
      <label class="form-label me-3">{{ 'Notification' | translate }}</label>
      <ng-select
        formControlName="transactionId"
        [items]="(unprocessedNotifications$ | async) || {}"
        bindLabel="transaction_label"
        bindValue="transaction_id"
      ></ng-select>
    </div>
    <div class="col-sm-3">
      <app-loading-button
        btnType="submit"
        btnClass="btn btn-primary mr-2"
        [isDisabled]="(loadingPayments$ | async) || (loadingNotifications$ | async)"
        [isLoading]="saving$ | async"
      >
        {{ 'Process' | translate }}
      </app-loading-button>
    </div>
  </form>
</div>
<div class="row">
  <div class="col-5">
    <h4>
      {{ 'Initiated payments' | translate }}
      <i *ngIf="(loadingPayments$ | async)" class="fa fa-spinner fa-spin"></i>
    </h4>

    <div class="input-group">
      <div class="input-group-text"><i class="far fa-search"></i></div>
      <input
        class="form-control"
        [placeholder]="'Payment reference, tenant or amount'"
        [ngModel]="searchQuery"
        (ngModelChange)="searchQueryDebounced$.next($event)"
      />
    </div>

    <table class="table">
      <thead>
        <tr>
          <th>{{ 'Tenant' | translate }}</th>
          <th>{{ 'Refence' | translate }}</th>
          <th>{{ 'Amount' | translate }}</th>
          <th>{{ 'Fee' | translate }}</th>
          <th>{{ 'Created At' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let payment of initiatedPayments$ | async"
          [ngClass]="{ 'table-info': form.value.paymentReference === payment.paymentReference }"
          (click)="form.controls.paymentReference.setValue(payment.paymentReference)"
        >
          <td>{{ payment.tenant.name }}</td>
          <td>{{ payment.paymentReference }}</td>
          <td><money [amount]="payment.amountInclVat" [currency]="payment.currency.code"></money></td>
          <td><money [amount]="payment.feeInclVat" [currency]="payment.currency.code"></money></td>
          <td>{{ payment.createdAt | date:'dd-MM-YY HH:mm' }}</td>
        </tr>
      </tbody>
    </table>

  </div>
  <div class="col-7">
    <h4>
      {{ 'Unprocessed notifications' | translate }}
      <i *ngIf="(loadingNotifications$ | async)" class="fa fa-spinner fa-spin"></i>
    </h4>

    <table class="table">
      <thead>
        <tr>
          <th>{{ 'Debtor' | translate }}</th>
          <th>{{ 'Amount' | translate }}</th>
          <th>{{ 'Booked At' | translate }}</th>
          <th>{{ 'Reference' | translate }}</th>
          <th>{{ 'Info' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let notification of unprocessedNotifications$ | async"
          [ngClass]="{ 'table-info': form.value.transactionId === notification.transaction_id }"
          (click)="form.controls.transactionId.setValue(notification.transaction_id)"
        >
          <td>{{ notification.debtor_name }}</td>
          <td>
            <span *ngIf="notification.amount">
              <money [amount]="notification.amount.amount" [currency]="notification.amount.currency"></money>
            </span>
          </td>
          <td>{{ notification.booking_datetime | date:'dd-MM-YY HH:mm' }}</td>
          <td>{{ notification.transaction_reference }}</td>
          <td>{{ notification.transaction_information }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</div>
