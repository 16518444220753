import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import { EburyActions } from 'app/store/actions';
import { EburyNotification, EburyPayment, State } from 'app/store/models';
import { notNull, untilDestroyed } from 'app/utils/rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-admin-ebury',
  templateUrl: './admin-ebury.component.html',
  styleUrls: ['admin-ebury.component.scss'],
})
export class AdminEburyComponent implements OnDestroy {
  loadingPayments$: Observable<boolean>;
  loadingNotifications$: Observable<boolean>;
  saving$: Observable<boolean>;
  initiatedPayments$: Observable<EburyPayment[]>;
  unprocessedNotifications$: Observable<EburyNotification[]>;
  searchQuery?: string;
  searchQueryDebounced$ = new BehaviorSubject<string|undefined>(undefined);
  form: UntypedFormGroup;

  constructor(
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    private actions$: Actions,
  ) {
    this.loadingPayments$ = this.store.select(x => x.admin.ebury.loadingPayments);
    this.loadingNotifications$ = this.store.select(x => x.admin.ebury.loadingNotifications);
    this.saving$ = this.store.select(x => x.admin.ebury.saving);
    this.initiatedPayments$ = this.store.select(x => x.admin.ebury.iniatedPayments).pipe(
      filter(notNull),
      startWith([]),
    );
    this.unprocessedNotifications$ = this.store.select(x => x.admin.ebury.unprocessedNotifications).pipe(
      filter(notNull),
      startWith([]),
      map(notifications => notifications.map(notification => ({
        ...notification, transaction_label: notification.transaction_reference || notification.transaction_information
      }))),
    );
    this.searchQueryDebounced$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    )
    .subscribe(() => this.store.dispatch(EburyActions.loadEburyPayments({
        query: this.searchQueryDebounced$.getValue(),
      }))
    );
    this.form = this.fb.group({
      transactionId: [ null, Validators.required],
      paymentReference: [null, Validators.required],
    });
    this.actions$
      .pipe(ofType(
        EburyActions.processEburyNotificationSuccess,
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.form.reset();
        this.load();
      });
    this.load();
  }

  load() {
    this.store.dispatch(EburyActions.loadEburyPayments({
      query: this.searchQueryDebounced$.getValue(),
    }));
    this.store.dispatch(EburyActions.loadEburyNotifications());
  }

  onFormSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.store.dispatch(EburyActions.processEburyNotification({
      paymentReference: this.form.value.paymentReference,
      notificationId: this.form.value.transactionId,
    }));
  }

  ngOnDestroy() { }
}
