import { Entity } from './hydra.model';
import { LegalEntity } from "./legal-entity.model";
import { PaymentProvider } from "./payment-provider.model";

export interface Country extends Entity {
  label: string; // for public endpoint
  value: string; // for public endpoint
  code: string;
  legalEntity: LegalEntity;
  paymentProviders: PaymentProvider[];
  companyIdentifierType: CompanyIdentifierType;
  deleted: boolean;
}

export enum CompanyIdentifierType {
  COMPANY_IDENTIFIER_VAT = 'VAT',
  COMPANY_IDENTIFIER_UEN = 'UEN',
  COMPANY_IDENTIFIER_NUMBER = 'NUMBER',
  COMPANY_IDENTIFIER_BN_BY_CRA = 'BN_BY_CRA',
}
