import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/services/api.service';
import { EburyActions } from 'app/store/actions';
import { EburyPayment, EburyNotification, HydraCollection } from 'app/store/models';

@Injectable()
export class EburyEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  loadEburyPayments$ = createEffect(() => this.actions$.pipe(
    ofType(EburyActions.loadEburyPayments),
    concatMap(({query}) => this.api.get<HydraCollection<EburyPayment>>({
      path: `/ebury_payments?exists[status]=false&exists[externalTransactionId]=false&page=1&pageSize=20${query ? '&query=' + query : ''}`,
    }).pipe(
      map(data => EburyActions.loadEburyPaymentsSuccess({ items: data['hydra:member'] })),
      catchError(err => of(EburyActions.loadEburyPaymentsFailure(err))),
    )),
  ));

  loadEburyNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(EburyActions.loadEburyNotifications),
    concatMap(() => this.api.get<EburyNotification[]>({
      path: '/unprocessed_ebury_notifications',
    }).pipe(
      map(data => EburyActions.loadEburyNotificationsSuccess({ items: data })),
      catchError(err => of(EburyActions.loadEburyNotificationsFailure(err))),
    )),
  ));

  finalizeEburyPayment$ = createEffect(() => this.actions$.pipe(
    ofType(EburyActions.processEburyNotification),
    concatMap(({notificationId, paymentReference}) => this.api.post({
      path: `/process_ebury_notification/${notificationId}/${paymentReference}`,
      body: {}
    }).pipe(
      map(() => EburyActions.processEburyNotificationSuccess()),
      catchError(err => of(EburyActions.processEburyNotificationFailure(err))),
    )),
  ));
}
