import { Action, createReducer, on } from '@ngrx/store';

import { CommonActions, EburyActions } from 'app/store/actions';
import { EburyState } from 'app/store/models';

const empty: EburyState = {
  iniatedPayments: null,
  unprocessedNotifications: null,
  loadingPayments: false,
  loadingNotifications: false,
  saving: false,
};

const reducer = createReducer<EburyState>(
  empty,
  on(CommonActions.clearAll, () => empty),

  on(EburyActions.loadEburyPayments, (state) => ({
    ...state,
    loadingPayments: true,
  })),
  on(EburyActions.loadEburyPaymentsSuccess, (state, { items }) => ({
    ...state,
    iniatedPayments: items,
    loadingPayments: false,
  })),
  on(EburyActions.loadEburyPaymentsFailure, (state) => ({
    ...state,
    loadingPayments: false,
  })),

  on(EburyActions.loadEburyNotifications, (state) => ({
    ...state,
    loadingNotifications: true,
  })),
  on(EburyActions.loadEburyNotificationsSuccess, (state, { items }) => ({
    ...state,
    unprocessedNotifications: items,
    loadingNotifications: false,
  })),
  on(EburyActions.loadEburyNotificationsFailure, (state) => ({
    ...state,
    loadingNotifications: false,
  })),

  on(EburyActions.processEburyNotification, (state) => ({
    ...state,
    saving: true,
  })),
  on(EburyActions.processEburyNotificationSuccess, (state) => ({
    ...state,
    saving: false,
  })),
  on(EburyActions.processEburyNotificationFailure, (state) => ({
    ...state,
    saving: false,
  })),
);

export function eburyReducer(state: EburyState | undefined, action: Action) {
  return reducer(state, action);
}
