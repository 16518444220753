import { CommonEffects } from './common.effects';
import { BundleEffects } from './bundle.effects';
import { BundleConfigurationEffects } from "./bundle-configuration.effects";
import { BundleInvoicingEffects } from './bundle-invoicing.effects';
import { CountryEffects } from './country.effects';
import { CurrencyPairEffects } from './currency-pair.effects';
import { CurrencyEffects } from './currency.effects';
import { DocumentEffects } from './document.effects';
import { EburyEffects } from './ebury.effects';
import { ExactOnlineEffects } from './exact-online.effects';
import { LegalEnttyEffects } from './legal-entity.effects';
import { OAuthClientEffects } from './oauth-client.effects';
import { PaymentProviderEffects } from './payment-provider.effects';
import { RegistrationEffects } from './registration.effects';
import { ServiceConfigurationEffects } from './service-configuration.effects';
import { ServiceEffects } from './service.effects';
import { ServiceProviderEffects } from './service-provider.effects';
import { SessionEffects } from './session.effects';
import { SubscriptionEffects } from './subscription.effects';
import { SubscriptionConfigurationEffects } from './subscription-configuration.effects';
import { SubscriptionInvoicingEffects } from './subscription-invoicing.effects';
import { TenantEffects } from './tenant.effects';
import { UserContextEffects } from './user-context.effects';
import { UserNotificationEffects } from './user-notification.effects';
import { UserServiceEffects } from './user-service.effects';
import { UserStatisticsEffects } from './user-statistics.effects';
import { UserTransactionEffects } from './user-transaction.effects';
import { UserEffects } from './user.effects';
import { WalletEffects} from './wallet.effects';

export const AppEffects = [
  CommonEffects,
  BundleEffects,
  BundleConfigurationEffects,
  BundleInvoicingEffects,
  CountryEffects,
  CurrencyPairEffects,
  CurrencyEffects,
  DocumentEffects,
  EburyEffects,
  ExactOnlineEffects,
  LegalEnttyEffects,
  OAuthClientEffects,
  PaymentProviderEffects,
  RegistrationEffects,
  ServiceConfigurationEffects,
  ServiceEffects,
  ServiceProviderEffects,
  SessionEffects,
  SubscriptionEffects,
  SubscriptionConfigurationEffects,
  SubscriptionInvoicingEffects,
  TenantEffects,
  UserContextEffects,
  UserNotificationEffects,
  UserServiceEffects,
  UserStatisticsEffects,
  UserTransactionEffects,
  UserEffects,
  WalletEffects,
];
