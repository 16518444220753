<div class="w-100" style="max-width:900px;position:relative">
  <div class="w-100 mt-2 mb-2 pe-none" style="min-height:150px;max-width:100%;overflow-x:auto; opacity: 0">
    <div class="d-flex flex-nowrap">
      <app-provider-card
        *ngFor="let s of linkedServices$ | async"
        class="d-flex"
        [provider]="s[0].serviceProvider"
      ></app-provider-card>
      <app-provider-card
        *ngFor="let s of unlinkedServices$ | async"
        class="d-flex"
        [provider]="s[0].serviceProvider"
      ></app-provider-card>
      <app-provider-card
        *ngFor="let sp of subProviders$ | async"
        class="d-flex"
        [provider]="sp"
      ></app-provider-card>
    </div>
  </div>
  <div class="text-muted pe-none" style="opacity: 0">{{ 'Our partners' | translate }}</div>

  <div class="h1 pt-4 pb-4 mb-0" *ngIf="(account$ | async) || {}; let account">
    <money [amount]="account.available" [currency]="account.currency"></money>
    <span class="ps-3 h3 text-muted" *ngIf="account.reserved > 0">
      <a routerLink="/transactions" [queryParams]="{showOnlyReserved: true}">(<money [amount]="account.reserved" [currency]="account.currency"></money> {{ 'reserved' | translate }})</a>
    </span>
    <p class="mb-0 h6 text-muted" *ngIf="firstVoucherToExpire">{{ 'of which' | translate }} <money [amount]="firstVoucherToExpire.amount" [currency]="account.currency"></money> {{ 'will expire on' | translate}} {{ firstVoucherToExpire.expiration | date:'dd/MM/yyyy'}}</p>
  </div>

  <!-- payment choice -->
  <div class="w-100 mb-3" *ngIf="getPayLater(paymentMethods$ | async)">
    <app-payment-choice (payLaterEmit)="handlePaymentChoiceChange($event)"></app-payment-choice>
  </div>
  <div class="card">
    <div class="card-body">
      <app-recharge-wallet [payLater]="payLater"></app-recharge-wallet>
    </div>
  </div>
</div>
