<mat-sidenav-container>
  <mat-sidenav #sidebar [(opened)]="opened" class="sidebar">
    <div data-cy="small-sidebar">
      <ng-container *ngTemplateOutlet="sidebarContent"></ng-container>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="fullscreen">
    <div class="d-flex align-items-stretch position-relative full-height">
      <div class="d-none d-md-block sidebar big-sidebar" data-cy="big-sidebar">
        <ng-container *ngTemplateOutlet="sidebarContent"></ng-container>
      </div>
      <div style="position:relative" class="p-3 main">
        <router-outlet></router-outlet>
      </div>
    </div>
    <button
      (click)="opened = !opened"
      class="btn btn-link btn-lg d-block d-md-none sidebar-open"
      data-cy="small-sidebar-toggle-button"
    >
      <span class="icon"><i class="far fa-bars"></i></span>
    </button>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #sidebarContent>
  <div class="h-100 d-flex flex-column justify-content-center" style="max-height:100vh">
    <div class="text-end sidebar-close">
      <button (click)="sidebar.close()" class="btn btn-link btn-lg" data-cy="small-sidebar-close-button">
        <span class="icon"><i class="far fa-times"></i></span>
      </button>
    </div>

    <a routerLink="/overview" class="d-block text-end p-3">
      <img src="/assets/img/portomundi-with-title.gif" alt="cinvio" class="sidebar-logo" />
    </a>

    <ng-container *ngIf="userContext$ | async; let userContext">
      <div class="profile nav-link text-end pb-3">
        {{ userContext.user.firstName }} {{ userContext.user.lastName }}

        <!-- Tenant select dropdown OR just company name -->
        <ng-container *ngIf="currentTenant$ | async; let tenant">
          <ng-container *ngIf="(tenants$ | async)?.length <= 1">
            <div class="h4 mb-1">{{ tenant.name }}</div>
          </ng-container>
          <div ngbDropdown [placement]="'bottom-right'" *ngIf="(tenants$ | async)?.length > 1">
            <button ngbDropdownToggle class="btn btn-link p-0 tenant-picker-btn" style="color:black">
              <div class="h4 mb-1">
                <i class="far fa-chevron-down"></i> {{ tenant.name }}
              </div>
            </button>
            <div ngbDropdownMenu><button class="dropdown-item" (click)="changeTenant()">
              {{ 'Switch company' | translate }}
            </button></div>
          </div>

          <div *ngIf="getSpenderAccount(userContext); let account">
            <money [amount]="account.available" [currency]="account.currency"></money>
          </div>
          <div *ngIf="getEarnerAccount(userContext); let account">
            <money [amount]="account.available" [currency]="account.currency"></money>
          </div>
        </ng-container>
      </div>

      <div class="sidemenu" data-cy="sidemenu">
        <ng-container *ngFor="let route of routes">
          <a
            *ngIf="checkRouteAndRole(userContext, route)"
            [routerLink]="route.url"
            routerLinkActive="active"
            class="nav-link text-end"
          >
            {{ route.name | translate }}
            <i class="far fa-fw {{ route.icon }}"></i>
          </a>
        </ng-container>
        <button class="btn btn-link w-100 nav-link text-end" (click)="logout()" data-cy="logout-button">
          {{ 'Logout' | translate }}
          <i class="far fa-sign-out-alt"></i>
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>
